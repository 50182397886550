
























































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import ImageInput from "@/components/ImageInput.vue";
import Base64FileInput from "@/components/Base64FileInput.vue";
import util from "@/services/util";

export default Vue.extend({
  components: { ImageInput, Base64FileInput },
  data() {
    return {
      util,
      userManager,
      id: null,
      item: {},
      messages: [],
      currentMessage: "",
      currentImages: [],
      currentFile: "",
      currentReplyToMessage: null,
      infoShown: false,
      urlFunc(item) {
        return Vue.filter("discussionMessageImageUrl")(item);
      },
    };
  },
  computed: {},
  methods: {
    async initialize() {
      this.id = this.$route.params.id;
      this.item = await apiClient.discussionGet(this.id);
      await this.refreshMessages();
    },
    async refreshMessages() {
      const { items } = await apiClient.discussionMessageGetAll(this.id, {
        rawOptions: {
          limit: -1,
          orders: {
            id: "DESC",
          },
        },
      });
      this.messages = items;
    },
    async sendCurrentMessage() {
      if (!this.currentMessage && !this.currentImages.length) {
        return;
      }
      if (
        await apiClient.discussionMessageCreate(this.id, {
          message: this.currentMessage,
          images: this.currentImages,
          file: this.currentFile,
          reply_to_id: this.currentReplyToMessage
            ? this.currentReplyToMessage.id
            : 0,
        })
      ) {
        if (this.currentImages.length || this.currentFile) {
          location.reload();
          return;
        }
        this.currentMessage = "";
        this.currentImages = [];
        this.currentReplyToMessage = null;
        this.currentFile = "";
        this.refreshMessages();
      }
    },
    messageHtml(message) {
      return message.replace(/\n/g, "<br/>");
    },
    isKd(message) {
      return message.user && message.user.roles.indexOf("kd") !== -1;
    },
    isGd(message) {
      return message.user && message.user.roles.indexOf("gd") !== -1;
    },
    replyTo(message) {
      this.currentReplyToMessage = message;
      this.$nextTick(() => {
        const $el = this.$refs.messageInput.$el.querySelector("textarea");
        if (!$el) {
          return;
        }
        console.log($el);
        $el.focus();
      });
    },
  },
  created() {
    this.initialize();
  },
});
